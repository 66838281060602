import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { ampRabbitMQPageEvent,ampRabbitMQFailurePageEvent, getParam } from "../../utils";
import { logData } from "../../utils/services";
import { useHistory } from "react-router-dom";
export function  Paymentfailed({ orderData, formatAMPM, txtData, lang }) {
    let path = JSON.parse(localStorage.getItem('pathname'))
  let finalURL = `https://pay-react-mymuze.hungama.com`+path.pathname+path.search
  console.log('finalURL',finalURL);
  const location = useLocation().search;
  const hostname = window.location.host;
  const pathname = useLocation().pathname;
  const couponcode = getParam(location, "couponcode");
  const [isLoadingOnce, setLoadingOnce] = useState(true);

  //  GA code
  const TRACKING_ID = `${process.env.REACT_APP_GA_CODE_PWA}`; // OUR_TRACKING_ID
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.pathname);
  }, [TRACKING_ID]);

  useEffect(() => {
    if (isLoadingOnce) {
      let source = "";
      if (
        orderData?.plan_type === "subscription" ||
        orderData?.plan_type === "event"
      ) {
        source = "PG Page_Subscription";
      } else if (orderData?.plan_type === "live_concert") {
        source = "PG Page_Live Event";
      } else {
        source = "PG Page_TVOD";
      }
      /*
      amplitudePageEvent(
        orderData?.identity,
        {
          "Page Type": "PG Page_failure",
          Source: source,
          "Plan Name": orderData?.plan_name,
          "Plan Validity": orderData?.plan_valid,
          "Plan Value": orderData?.plan_amount,
          order_id: orderData?.order_id,
          "Page Language": lang ? lang : "en",
        },
        orderData?.product_id,
        orderData?.platform_id
      );
      */
      /* amplitude with RabbitMQ */
      ampRabbitMQFailurePageEvent({
        identity: orderData?.identity,
        "Page Type": "PG Page_failure",
        Source: source,
        "Plan Name": orderData?.plan_name,
        "Plan Validity": orderData?.plan_valid,
        "Plan Value": orderData?.plan_amount,
        order_id: orderData?.order_id,
        "Page Language": lang ? lang : "en",
        product_id: orderData?.product_id,
        platform_id: orderData?.platform_id,
      });

      /* Frontend Logs */
      const lgData = {
        url: hostname + pathname,
        params: location,
        couponcode: couponcode,
        order_data: orderData,
        log_time: new Date().toLocaleString().replace(",", ""),
        log_type: "FRONTEND_PAYMENT_RESPONSE | Fail",
      };
      logData({ logData: JSON.stringify(lgData) }).then(() => {});
    }
    setLoadingOnce(false);
  }, [
    orderData,
    isLoadingOnce,
    lang,
    couponcode,
    hostname,
    location,
    pathname,
  ]);

  let history = useHistory();
  const goToPreviousPath=()=>{
      history.goBack()
      // if(platform_id==='1'){
      //     window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/wvclose.php`;
      // }
  }
  return (
    <div className="wrapper">
      <div className="main-container">
        <div className="pay-head">
          {/* <span onClick={goToPreviousPath}  className="pay-back"><img src={process.env.REACT_APP_ASSETS_URL + "/mobile/images/back-arrow.svg"} alt="arrow" /></span> */}
          <span>{txtData?.pay_mob_fail_title_head}</span>
        </div>
        <div className="pay-optinbox">
          <div className="pay-optinbox-inner">
            <div className="pay-mode-box failed-box">
              {/*   <img alt="gold-logo" src={process.env.REACT_APP_ASSETS_URL + "/mobile/images/hungama-gold.png"} className="hungama-gold-logo" /><br />*/}
              <img
                alt="payment-failed"
                src={
                  process.env.REACT_APP_ASSETS_URL + "/mobile/images/failed.png"
                }
                className="payment-failed"
              />
              <h3 className="payment-failed-title">
                {txtData?.pay_web_fail_text1}
              </h3>
              {orderData?.payment_id === "14" && (
                <p className="pf-info">
                  {/* Don’t worry your coins are safe! If your coins are debited
                  from your account it will be refunded In 5-6 working days */}
                  {txtData?.pay_web_fail_text9_coin}
                </p>
              )}
              {orderData?.payment_id !== "14" && (
                <p className="pf-info">
                  {/* Don’t worry your money is safe! If your money is debited from
                  your account it will be refunded in 5-6 working days */}
                  {txtData?.pay_web_fail_text2}
                </p>
              )}
              <p className="pf-info">
                  {/* Don’t worry your money is safe! If your money is debited from
                  your account it will be refunded in 5-6 working days */}
                  {txtData?.pay_web_fail_sub_text1}
                </p>
                <p className="pf-info">
                  {/* Don’t worry your money is safe! If your money is debited from
                  your account it will be refunded in 5-6 working days */}
                  {txtData?.pay_web_fail_sub_text2}
                </p>
                <p className="pf-info">
                  {/* Don’t worry your money is safe! If your money is debited from
                  your account it will be refunded in 5-6 working days */}
                  {txtData?.pay_web_fail_sub_text3}
                </p>
            </div>
          
            {/* <p className="text-center">
              <a
                href={`${process.env.REACT_APP_HUNGAMA_WEB_URL}/faqs`}
                className="need-help"
              >
                {txtData?.pay_mob_need_help}
              </a>
            </p> */}
          </div>
        </div>
      </div>
      <div className="footer-action">
        <a
          href={finalURL}
          onClick={goToPreviousPath}
          className="w-100 blueprimary_btn"
        >
          {/* Retry */}
          {txtData?.pay_web_fail_text3}
        </a>
      </div>
    </div>
  );
}
