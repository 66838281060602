import axios from "axios";
import { headers, SUCCESS, FAILED } from "../../constants";

export async function plans({ product_id, platform_id, country, identity }) {
  const body = {
    product_id: product_id,
    platform_id: platform_id,
    country: country,
    identity: identity,
  };

  return axios({
    method: "GET",
    url: getReactApiUrl(`/v1/billing/plans?product_id=${body.product_id}&platform_id=${body.platform_id}&country=${body.country}&identity=${body.identity}`),
    headers: headers,
    // data: body,
  })
    .then((res) => {
      if (res.data.code >= 200 && res.data.code <= 299) {
        return { status: SUCCESS, data: res.data };
      }
      throw new Error(res);
    })
    .catch((err) => {
      console.log(JSON.stringify(err));
      return { status: FAILED, data: "" };
    });
}


export async function vodacomPlans({ product_id, platform_id, country, identity }) {
  const body = {
    product_id: product_id,
    platform_id: platform_id,
    country: country,
    identity: identity,
  };

  return axios({
    method: "GET",
    url: 'https://mymuzetransaction.api.hungama.com/v1/payment/package',
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "authorization": "Basic dm9kYWNvbWxpdjI0OnFuTDdyaixsMzZuW1pha0k=",
    },
    // data: body,
  })
    .then((res) => {
      if (res.data.code >= 200 && res.data.code <= 299) {
        return { status: SUCCESS, data: res.data };
      }
      throw new Error(res);
    })
    .catch((err) => {
      console.log(JSON.stringify(err));
      return { status: FAILED, data: "" };
    });
}
//free trial
export async function checkFreeTrialStatus({ product_id, country, identity }) {
  const body = {
    product_id: product_id,
    country: country,
    identity: identity,
  };

  return axios({
    method: "POST",
    url: getReactApiUrl(`/v1/user/check_free_trial_status`),
    headers: headers,
    data: body,
  })
    .then((res) => {
      console.log("res in api", res, res.data.statusCode);
      if (res.data.statusCode >= 200 && res.data.statusCode <= 299) {
        return { status: SUCCESS, data: res.data.data };
      }
      throw new Error(res);
    })
    .catch((err) => {
      console.log(JSON.stringify(err));
      return { status: FAILED, data: "" };
    });
}

export async function verifyCouponCode(couponcode) {
  console.log("couponcode", couponcode);
  return axios({
    method: "GET",
    url: getReactHungamaUrl(
      `/coupons/get_discounted_value.php?coupon_code=${couponcode}`
    ),
  })
    .then((res) => {
      if (!res.coupon_details) {
        return { status: SUCCESS, data: res.data };
      }
    })
    .catch((err) => {
      console.log(JSON.stringify(err));
      return { status: FAILED, data: "" };
    });
}

export async function language(lang, product_id = "1") {
  const body = {
    lang: lang,
    product_id: product_id,
  };

  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/v1/lang?lang=${body.lang}&product_id=${body.product_id}`,
    headers: headers,
    data: body,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
}

export async function language3(lang, product_id = "4") {
  const body = {
    lang: lang,
    product_id: product_id,
  };
  console.log('body',body)
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/v1/lang?lang=${body.lang}&product_id=${body.product_id}`,
    headers: headers,
    data: body,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
}

export async function language5(lang, product_id = "5") {
  const body = {
    lang: lang,
    product_id: product_id,
  };

  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/v1/lang?lang=${body.lang}&product_id=${body.product_id}`,
    headers: headers,
    data: body,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
}


export async function logData(body) {
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/v1/frontendlogs`,
    headers: headers,
    data: body,
  })
    .then((res) => {
      // console.log(res);
      if (res.status >= 200 && res.status <= 299) {
        return { status: SUCCESS, data: res.data };
      }
      throw new Error(res);
    })
    .catch((err) => {
      console.log(JSON.stringify(err));
      return { status: FAILED, data: "" };
    });
}

export async function updateOrderStatus(identity) {
  const body = {
    identity: identity,
  };

  return axios({
    method: "PUT",
    url: getReactApiUrl(`/v1/billing/orders/update_order_status`),
    headers: headers,
    data: body,
  })
    .then((res) => {
      if (res.data) {
        return { status: SUCCESS };
      }
      throw new Error(res);
    })
    .catch((err) => {
      console.log(JSON.stringify(err));
      return { status: FAILED };
    });
}

function getReactApiUrl(path) {
  return `${process.env.REACT_APP_UAT_API_URL}${path}`;
}

function getReactHungamaUrl(path) {
  return `${process.env.REACT_APP_HANGAMA_URL}${path}`;
}

export function Capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

//check user recent order
export async function checkUserRecentOrder(body) {
  // console.log("body ", body);
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_UAT_API_URL}/v1/user/check_order/check_recent_order`,
    headers: headers,
    data: body,
  })
    .then((res) => {
      // console.log("res", res);
      if (res.status >= 200 && res.status <= 299) {
        return { status: SUCCESS, data: res.data.data };
      }
      throw new Error(res);
    })
    .catch((err) => {
      console.log(JSON.stringify(err));
      return { status: FAILED, data: "" };
    });
}

//
export async function HMCheckUserExistsOrSingUp(
  mobileno,
  action = "checkuserexists_or_mobile_signup"
) {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_HANGAMA_URL}/webservices/user_auth.php?mobile_no=${mobileno}&action=${action}`,
    headers: { "Content-Type": "application/json" },
  })
    .then((res) => {
      // console.log("res.data", res, res?.data?.code);
      if (res?.data?.code === "200") {
        return { status: true, data: res.data.data };
      } else return { status: false, data: {} };
    })
    .catch((err) => {
      console.log("HMCheckUserExistsOrSingUp:: errr", err);
      return err;
    });
}

/* RabbitMQ Api */
export async function rabbitMQReceiver(body) {
  let newMQBody = {
    ...body,
    "auth-key": process.env.REACT_APP_MQ_AUTH_KEY,
    queue_name: process.env.REACT_APP_QUEUE_NAME,
  };
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/v1/notify/mq/receiver`,
    headers: headers,
    data: newMQBody,
  })
    .then((res) => {
      // console.log(res);
      if (res.status >= 200 && res.status <= 299) {
        return { status: SUCCESS, data: res.data };
      }
      throw new Error(res);
    })
    .catch((err) => {
      console.log(JSON.stringify(err));
      return { status: FAILED, data: "" };
    });
}

export async function rabbitMQReceiver1(body) {
  let newMQBody = {
    ...body,
    "auth-key": process.env.REACT_APP_MQ_AUTH_KEY,
    queue_name: process.env.REACT_APP_QUEUE_NAME,
  };
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/v1/notify/mq/successReceiver`,
    headers: headers,
    data: newMQBody,
  })
    .then((res) => {
      // console.log(res);
      if (res.status >= 200 && res.status <= 299) {
        return { status: SUCCESS, data: res.data };
      }
      throw new Error(res);
    })
    .catch((err) => {
      console.log(JSON.stringify(err));
      return { status: FAILED, data: "" };
    });
}

export async function rabbitMQReceiver2(body) {
  let newMQBody = {
    ...body,
    "auth-key": process.env.REACT_APP_MQ_AUTH_KEY,
    queue_name: process.env.REACT_APP_QUEUE_NAME,
  };
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/v1/notify/mq/failureReceiver`,
    headers: headers,
    data: newMQBody,
  })
    .then((res) => {
      // console.log(res);
      if (res.status >= 200 && res.status <= 299) {
        return { status: SUCCESS, data: res.data };
      }
      throw new Error(res);
    })
    .catch((err) => {
      console.log(JSON.stringify(err));
      return { status: FAILED, data: "" };
    });
}

export async function rabbitMQReceiver3(body) {
  let newMQBody = {
    ...body,
    "auth-key": process.env.REACT_APP_MQ_AUTH_KEY,
    queue_name: process.env.REACT_APP_QUEUE_NAME,
  };
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/v1/notify/mq/subBtnClickReceiver`,
    headers: headers,
    data: newMQBody,
  })
    .then((res) => {
      // console.log(res);
      if (res.status >= 200 && res.status <= 299) {
        return { status: SUCCESS, data: res.data };
      }
      throw new Error(res);
    })
    .catch((err) => {
      console.log(JSON.stringify(err));
      return { status: FAILED, data: "" };
    });
}

/* Moengage Api */
export async function rabbitMQReceiverMoengage(body) {
  let newMQBody = {
    ...body,
    "auth-key": process.env.REACT_APP_MQ_AUTH_KEY,
    queue_name: process.env.REACT_APP_QUEUE_NAME_MOENGAGE,
  };
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/v1/notify/mq/receiver`,
    headers: headers,
    data: newMQBody,
  })
    .then((res) => {
      // console.log(res);
      if (res.status >= 200 && res.status <= 299) {
        return { status: SUCCESS, data: res.data };
      }
      throw new Error(res);
    })
    .catch((err) => {
      console.log(JSON.stringify(err));
      return { status: FAILED, data: "" };
    });
}

export async function rabbitMQReceiverMoengage1(body) {
  let newMQBody = {
    ...body,
    "auth-key": process.env.REACT_APP_MQ_AUTH_KEY,
    queue_name: process.env.REACT_APP_QUEUE_NAME_MOENGAGE,
  };
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/v1/notify/mq/successReceiver`,
    headers: headers,
    data: newMQBody,
  })
    .then((res) => {
      // console.log(res);
      if (res.status >= 200 && res.status <= 299) {
        return { status: SUCCESS, data: res.data };
      }
      throw new Error(res);
    })
    .catch((err) => {
      console.log(JSON.stringify(err));
      return { status: FAILED, data: "" };
    });
}

export async function rabbitMQReceiverMoengage2(body) {
  let newMQBody = {
    ...body,
    "auth-key": process.env.REACT_APP_MQ_AUTH_KEY,
    queue_name: process.env.REACT_APP_QUEUE_NAME_MOENGAGE,
  };
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/v1/notify/mq/failureReceiver`,
    headers: headers,
    data: newMQBody,
  })
    .then((res) => {
      // console.log(res);
      if (res.status >= 200 && res.status <= 299) {
        return { status: SUCCESS, data: res.data };
      }
      throw new Error(res);
    })
    .catch((err) => {
      console.log(JSON.stringify(err));
      return { status: FAILED, data: "" };
    });
}

export async function rabbitMQReceiverMoengage3(body) {
  let newMQBody = {
    ...body,
    "auth-key": process.env.REACT_APP_MQ_AUTH_KEY,
    queue_name: process.env.REACT_APP_QUEUE_NAME_MOENGAGE,
  };
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/v1/notify/mq/subBtnClickReceiver`,
    headers: headers,
    data: newMQBody,
  })
    .then((res) => {
      // console.log(res);
      if (res.status >= 200 && res.status <= 299) {
        return { status: SUCCESS, data: res.data };
      }
      throw new Error(res);
    })
    .catch((err) => {
      console.log(JSON.stringify(err));
      return { status: FAILED, data: "" };
    });
}
