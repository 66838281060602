import axios from "axios";
import { headers, SUCCESS, FAILED } from "../../constants";
// import { amplitudeBtnEvent } from "./index"
export async function payments({
  plan_id,
  plan_type,
  content_id,
  identity,
  product_id,
  platform_id,
  country,
}) {
  const body = {
    product_id: product_id,
    platform_id: platform_id,
    country: country,
    plan_id: plan_id,
    plan_type: plan_type,
    content_id: content_id,
    identity: identity,
  };

  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/v1/billing/payments`,
    headers: headers,
    data: body,
  })
    .then((res) => {
      if (res.data.code >= 200 && res.data.code <= 299) {
        return { status: SUCCESS, data: res.data };
      }
      throw new Error(res);

      // const featuredData = res.data.featuredPayment;
      // const planData = res.data.planInfo;
      // const paymentsdata = res.data.payments;
      // const walletPaymentsdata = res.data.walletPayments;
      // const upiPaymentsdata = res.data.upiPayments;
      // const netBankingPaymentsdata = res.data.netBankingPayments;
      // // console.log(paymentsdata);
      // // console.log("payments.total_user_coins");
      // // console.log();
      // setTotal_user_coins(res?.data?.total_user_coins);
      // setFeatured(featuredData);
      // setPlan(planData);
      // setPayments(paymentsdata);
      // setWallets(walletPaymentsdata);
      // setNetBankingPayments(netBankingPaymentsdata);
      // setUpiPayments(upiPaymentsdata);
      // setLoading(false);
      // setisLoadingVerify(true);

      // /* amplitude code start*/
      // let pageType = '';
      // if (plan_type === 'subscription') {
      //     pageType = 'PG Page_Subscription';
      // } else if (plan_type === 'live_concert') {
      //     pageType = 'PG Page_Live Event';
      // } else {
      //     pageType = 'PG Page_TVOD';
      // }

      // amplitudePageEvent(identity, {
      //     "Page Type": pageType,
      //     "Source": utm_source,
      //     "Plan Name": planData.plan_name,
      //     "Plan Validity": planData.plan_valid,
      //     "Plan Value": planData.plan_price.toFixed(2),
      //     "Plan Currency": planData.plan_currency,
      //     "Plan ID": plan_id,
      // })
      /* amplitude code end*/
    })
    .catch((err) => {
      console.log(JSON.stringify(err));
      return { status: FAILED, data: "" };
    });
}

export async function verifyUpi({ payment_id, product_id, couponcode, upiId, identity}) {
  // amplitudeBtnEvent(identity, "Btn_Verify_UPI");
  // const payment_id = e.target.getAttribute("payment_id");
  // const upiid = formData.upiid;
  const urlVerify = `${process.env.REACT_APP_HANGAMA_URL}/billing/api.php?payment_id=${payment_id}&product_id=${product_id}&rtype=check_vpa&vpa=${upiId}&identity=${identity}&coupon_code=${couponcode}`;
  return axios
    .get(urlVerify)
    .then((res) => {
      console.log(res);
      if (res.status >= 200 && res.status <= 299) {
        return { status: SUCCESS, data: res.data };
      }
      throw new Error(res);

      // if (res?.data?.isVPAValid) {
      //     // setIsUpiValid(false)
      //     // handleElement(e);
      // }
    })
    .catch((err) => {
      console.log(JSON.stringify(err));
      return { status: FAILED, data: "" };
    });
}

export async function payUpi(
  upi_type,
  data,
  pg,
  bankcode,
  urlparams,
  couponcode,
  hungamaIdentity,
  jusPayUser,
) {
  let url;
  /* const url = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?upi_type=${upi_type}&payment_id=${data.payment_id}&plan_details_id=${data.plan_details_id}&pg=${pg}&bankcode=${bankcode}&type=upi_intent&${urlparams}&coupon_code=${couponcode}`; */
  const storedWebsite = localStorage.getItem("website");
  if(storedWebsite == 'true' || storedWebsite == true){
   url = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?upi_type=${upi_type}&payment_id=${data.payment_id}&plan_details_id=${data.plan_details_id}&pg=${pg}&bankcode=${bankcode}&type=upi_intent&${urlparams}&coupon_code=${couponcode}&juspay_identity=${jusPayUser.juspay_identity}&hid=${hungamaIdentity}&frequency=${jusPayUser?.plan_info?.plan_name}&og_price=${jusPayUser?.plan_info?.original_price}&plan_price=${jusPayUser?.plan_info?.plan_price}`;
  }
  else{
     url = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?upi_type=${upi_type}&payment_id=${data.payment_id}&plan_details_id=${data.plan_details_id}&pg=${pg}&bankcode=${bankcode}&type=upi_intent&${urlparams}&coupon_code=${couponcode}&juspay_identity=${jusPayUser.juspay_identity}&hid=${hungamaIdentity}&frequency=${jusPayUser?.plan_info?.plan_name}&og_price=${jusPayUser?.plan_info?.original_price}&plan_price=${jusPayUser?.plan_info?.plan_price}`;
  }
 console.log('URL',urlparams);
  return axios
    .get(url)
    .then((res) => {
      // console.log(res);
      if (res.status >= 200 && res.status <= 299) {
        return { status: SUCCESS, data: res.data };
      }
      throw new Error(res);
    })
    .catch((err) => {
      console.log(JSON.stringify(err));
      return { status: FAILED, data: "" };
    });
}

export async function checkUpiPaymentStatus(
  payment_id,
  product_id,
  order_id,
  couponcode,
  identity
) {
  console.log("coming checkUpiPaymentStatus");
  const url = `${process.env.REACT_APP_HANGAMA_URL}/billing/api.php?payment_id=${payment_id}&product_id=${product_id}&rtype=order_status_check&order_id=${order_id}&coupon_code=${couponcode}&identity=${identity}`;
  return axios
    .get(url)
    .then((res) => {
      console.log(res);
      if (res.status >= 200 && res.status <= 299) {
        return { status: SUCCESS, data: res.data };
      }
      throw new Error(res);
    })
    .catch((err) => {
      console.log(JSON.stringify(err));
      return { status: FAILED, data: "" };
    });
}

export async function payWithCreditCard(paymentsData) {
  // const body = {
  //     "product_id": product_id,
  //     "platform_id": 1,
  //     "country": country,
  //     "plan_id": plan_id,
  //     "plan_type": plan_type,
  //     "content_id": content_id,
  //     "identity": identity
  // };
  // return axios({
  //     method: "POST",
  //     // url: `${process.env.REACT_APP_API_URL}/v1/billing/payments`,
  //     url: `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?payment_id=${data.payment_id}&plan_details_id=${data.plan_details_id}&type=charge&${urlparams}&coupon_code=${coupon_code}`,
  //     headers: headers,
  //     data: body
  // })
  //     .then((res) => {
  //         if (res.data.code >= 200 && res.data.code <= 299) {
  //             return { "status": SUCCESS, data: res.data };
  //         }
  //         throw new Error(res);
  //     })
  //     .catch((err) => {
  //         console.log(JSON.stringify(err));
  //         return { "status": FAILED, data: "" }
  //     });
}
export async function checkOrder(order_id) {
  const body = {
    order_id: order_id,
  };

  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_UAT_API_URL}/v1/user/check_order`,
    headers: headers,
    data: body,
  })
    .then((res) => {
      // console.log(res);
      if (res.status >= 200 && res.status <= 299) {
        return { status: SUCCESS, data: res.data.data };
      }
      throw new Error(res);
    })
    .catch((err) => {
      console.log(JSON.stringify(err));
      return { status: FAILED, data: "" };
    });
}

export async function checkCreditCardNumber(cardNumber,plan_type) {
  const url = `${process.env.REACT_APP_HANGAMA_URL}/billing/api.php?payment_id=15&product_id=1&rtype=check_card_type&card_bin=${cardNumber}&plan_type=${plan_type}`;

  return axios
    .get(url)
    .then((res) => {
      if (res.status >= 200 && res.status <= 299) {
        return { status: SUCCESS, data: res.data };
      }
      throw new Error(res);
    })
    .catch((err) => {
      console.log(JSON.stringify(err));
      return { status: FAILED, data: "" };
    });
}
export function validateCardNumber(n) {
  let numberOfDigit = n.toString().length;
  let numberArray = n.toString().split("");
  if (numberOfDigit % 2 === 0) {
    for (let i = 0; i < numberOfDigit; i++) {
      if (i % 2 === 0 || i === 0) {
        numberArray[i] = Number(numberArray[i]);
        numberArray[i] *= 2;
        if (numberArray[i] > 9) {
          numberArray[i] = numberArray[i] - 9;
        }
      } else {
        continue;
      }
    }
  } else {
    for (let i = 0; i < numberOfDigit; i++) {
      if (i % 2 === 0) {
        continue;
      } else {
        console.log(typeof numberArray[i]);
      }
    }
  }
  let sum = 0;
  for (let i = 0; i < numberArray.length; i++) {
    sum += Number(numberArray[i]);
  }
  if (sum % 10 === 0) {
    return true;
  } else {
    return false;
  }
}
